<template>
  <div>
    <transition :css="false" @enter="pageEnter" @leave="pageLeave" mode="out-in">
      <keep-alive include="magazineList">
        <router-view />
      </keep-alive>
    </transition>
  </div>
</template>
<script>
  import {TweenMax} from "gsap";

  export default {
    name: "magazine",
    metaInfo() {
      return {
        title: "Magazine",
        titleTemplate: "%s | e · motion",
        meta: [
          {
            property: "og:title",
            content: "Magazine | e · motion",
            vmid: "og:title"
          },
          {
            property: "og:url",
            content: "https://www.emotion.co.kr/magazine/",
            vmid: "og:url"
          }
        ]
      };
    },
    methods: {
      pageEnter(el, done) {
        const els = [el, document.querySelector(".banner-area"), document.querySelector("footer")];
        TweenMax.fromTo(
          els,
          0.3,
          {opacity: 0},
          {
            opacity: 1,
            onComplete: () => {
              TweenMax.set(el, {clearProps: "all"});
              done();
            }
          }
        );
      },
      pageLeave(el, done) {
        const els = [el, document.querySelector(".banner-area"), document.querySelector("footer")];
        TweenMax.fromTo(
          els,
          0.3,
          {opacity: 1},
          {
            opacity: 0,
            onComplete: () => {
              done();
            }
          }
        );
      }
    }
  };
</script>
<style scoped></style>
